import React from 'react'
import TaiwanHunData from '../../containers/TaiwanHunData'

const TaiwanHundred = () => {
  return (
    <TaiwanHunData />
  )
}

export default TaiwanHundred
